<template>
  <div id="user-view">
    <div class="text-center" v-if="loading">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
      <v-form
        v-else
        ref="formRef"
        @submit.prevent="saveData"
      >
      <v-card class="mb-5">
        <v-row class="ma-0 pa-0">
          <v-col
            cols="8"
            sm="6"
            class="pa-0"
          >
            <v-card-title>Kampagne</v-card-title>
            <v-card-text>
                <v-text-field
                  v-model="form.mainData.name"
                  label="Name"
                  type="text"
                  outlined
                  dense
                  hide-details="auto"
                  placeholder="Name"
                  required
                  :rules="[validators.required]"
                ></v-text-field>
            </v-card-text>
          </v-col>
          <v-col
            cols="4"
            sm="6"
          >
            <div>
              <v-img
                width="120"
                src="@/assets/images/misc/tree-4.png"
                class="gamification-tree-4"
              ></v-img>
              <v-img
                width="80"
                src="@/assets/images/3d-characters/pose-2.png"
                class="gamification-john-pose-2"
              ></v-img>
              <v-img
                width="100"
                src="@/assets/images/misc/tree.png"
                class="gamification-tree"
              ></v-img>
            </div>
          </v-col>
        </v-row>
      </v-card>
      <v-row>
        <v-col
          cols="12"
          md="12"
          lg="12"
        >
          <v-row class="user-bio-panel">
            <v-col cols="12">
              <v-card class="">
                <v-card-title>Selektionen</v-card-title>
                <v-card-text class=" justify-center flex-column mb-0 ">
                  <v-row>
                    <v-col
                      cols="12"
                      md="8"
                    >
                      <v-select
                        class="mb-3"
                        v-model="form.channels"
                        multiple
                        hide-details="auto"
                        outlined
                        dense
                        chips
                        :items="channels"
                        item-text="displayName"
                        item-value="idNipKanal"
                        label="Auswahl Kanäle"
                        required
                        :rules="[validators.minSelectionsRule(1)]"
                      ></v-select>
                      <v-select
                        class="mb-3"
                        v-model="form.segments"
                        multiple
                        hide-details="auto"
                        outlined
                        dense
                        chips
                        :items="segments"
                        item-text="displayName"
                        item-value="idNipSegment"
                        label="Auswahl eigene Segmente"
                        required
                        :rules="[validators.minSelectionsRule(1)]"
                      ></v-select>
                      <v-select
                        class="mb-3"
                        v-model="form.mainData.freieAuswahlJson.geschlecht"
                        multiple
                        hide-details="auto"
                        outlined
                        dense
                        chips
                        :items="genders"
                        item-text="label"
                        item-value="value"
                        label="Auswahl Geschlecht"
                        required
                        :rules="[validators.minSelectionsRule(1)]"
                      ></v-select>
                      <v-select
                        class="mb-3"
                        v-model="form.mainData.freieAuswahlJson.laender"
                        multiple
                        hide-details="auto"
                        outlined
                        dense
                        chips
                        :items="countries"
                        item-text="label"
                        item-value="value"
                        label="Auswahl Länder"
                        required
                        :rules="[validators.minSelectionsRule(1)]"
                      ></v-select>
                      <v-select
                        class="mb-3"
                        v-model="form.mainData.freieAuswahlJson.sprachen"
                        multiple
                        hide-details="auto"
                        outlined
                        dense
                        chips
                        :items="languages"
                        item-text="label"
                        item-value="value"
                        label="Auswahl Sprachen"
                        required
                        :rules="[validators.minSelectionsRule(1)]"
                      ></v-select>
                      <v-select
                        class="mb-3"
                        v-model="form.mainData.freieAuswahlJson.plattformen"
                        multiple
                        hide-details="auto"
                        outlined
                        dense
                        chips
                        :items="platforms"
                        item-text="label"
                        item-value="value"
                        label="Auswahl Plattformen"
                        required
                        :rules="[validators.minSelectionsRule(1)]"
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >

                      <v-icon size="40" class="refreshIcon" @click="runPotentialAnalyze">
                        {{ icons.mdiRefresh }}
                      </v-icon>

                      <vue-apex-charts
                        id="chart-sales-overview"
                        :options="chartOptions"
                        :series="chartData"
                        class="d-flex align-center justify-center"
                        width="71%"
                      ></vue-apex-charts>

                      <v-slider
                        label="Max."
                        v-model="form.mainData.freieAuswahlJson.maxAbonnenten"
                        class="mt-3 flex align-center"
                        hide-details
                      >
                        <template #append>
                          <span class="align-center">{{form.mainData.freieAuswahlJson.maxAbonnenten}}</span>
                        </template>
                      </v-slider>

                      <v-range-slider
                        label="Altersgruppe"
                        v-model="altersgruppe"
                        class="flex align-center"
                        hide-details
                      >
                        <template #append>
                          <span class="align-center">{{altersgruppe[0]}} - {{altersgruppe[1]}}</span>
                        </template>
                      </v-range-slider>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-card
            title="Nachricht"
            class="fill-height"
          >
            <v-card-title>Nachricht</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="form.message.titel"
                    label="Titel"
                    type="text"
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Titel"
                    required
                    :rules="[validators.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="form.message.text"
                    label="Text"
                    type="text"
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Text"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="form.message.url"
                    label="URL"
                    type="text"
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="https://..."
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-row class="align-center">
                    <v-col :cols="imagePreviews.messageIcon != '' ? '10' : '12'" :md="imagePreviews.messageIcon != '' ? '10' : '12'">
                      <v-file-input
                        @change="previewImage('messageIcon')"
                        @click:clear="deleteImage('messageIcon')"
                        type="file"
                        v-model="messageIcon"
                        label="Icon"
                        outlined
                        dense
                        hide-details="auto"
                        placeholder="Icon"
                      ></v-file-input>
                    </v-col>
                    <v-col cols="2" md="2" v-if="imagePreviews.messageIcon != ''">
                      <v-img
                        :src="imagePreviews.messageIcon"
                        :width="100"
                      ></v-img>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="12">
                  <v-row class="align-center">
                    <v-col :cols="imagePreviews.messageImage != '' ? '10' : '12'" :md="imagePreviews.messageImage != '' ? '10' : '12'">
                      <v-file-input
                        @change="previewImage('messageImage')"
                        @click:clear="deleteImage('messageImage')"
                        type="file"
                        v-model="messageImage"
                        label="Grafik"
                        outlined
                        dense
                        hide-details="auto"
                        placeholder="Grafik"
                      ></v-file-input>
                    </v-col>
                    <v-col cols="2" md="2" v-if="imagePreviews.messageImage != ''">
                      <v-img
                        :src="imagePreviews.messageImage"
                        :width="100"
                      ></v-img>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-card outlined>
                    <v-card-text>
                      <v-switch v-model="form.messageButtons[0].aktiv" class="mx-0" label="Button 1" hide-details></v-switch>
                      <template v-if="form.messageButtons[0].aktiv">
                        <v-col
                          cols="12"
                          sm="12"
                        >
                          <v-select
                            v-model="form.messageButtons[0].aktion"
                            hide-details="auto"
                            outlined
                            dense
                            :items="buttonActions"
                            item-text="label"
                            item-value="value"
                            placeholder="Aktion"
                          ></v-select>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                        >
                          <v-text-field
                            v-model="form.messageButtons[0].url"
                            label="URL"
                            type="text"
                            outlined
                            dense
                            hide-details="auto"
                            placeholder="https://..."
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="12">
                          <v-row class="align-center">
                            <v-col :cols="imagePreviews.button1_icon != '' ? '10' : '12'" :md="imagePreviews.button1_icon != '' ? '10' : '12'">
                              <v-file-input
                                @change="previewImage('button1_icon')"
                                @click:clear="deleteImage('button1_icon')"
                                type="file"
                                v-model="button1_icon"
                                label="Icon"
                                outlined
                                dense
                                hide-details="auto"
                                placeholder="Icon"
                              ></v-file-input>
                            </v-col>
                            <v-col cols="2" md="2" v-if="imagePreviews.button1_icon != ''">
                              <v-img
                                :src="imagePreviews.button1_icon"
                                :width="100"
                              ></v-img>
                            </v-col>
                          </v-row>
                        </v-col>
                      </template>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-card outlined>
                    <v-card-text>
                      <v-switch v-model="form.messageButtons[1].aktiv" class="mx-0" label="Button 2" hide-details></v-switch>
                      <template v-if="form.messageButtons[1].aktiv">
                        <v-col
                          cols="12"
                          sm="12"
                        >
                          <v-select
                            v-model="form.messageButtons[1].aktion"
                            hide-details="auto"
                            outlined
                            dense
                            :items="buttonActions"
                            item-text="label"
                            item-value="value"
                            placeholder="Aktion"
                          ></v-select>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                        >
                          <v-text-field
                            v-model="form.messageButtons[1].url"
                            label="URL"
                            type="text"
                            outlined
                            dense
                            hide-details="auto"
                            placeholder="https://..."
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="12">
                          <v-row class="align-center">
                            <v-col :cols="imagePreviews.button2_icon != '' ? '10' : '12'" :md="imagePreviews.button2_icon != '' ? '10' : '12'">
                              <v-file-input
                                @change="previewImage('button2_icon')"
                                @click:clear="deleteImage('button2_icon')"
                                type="file"
                                v-model="button2_icon"
                                label="Icon"
                                outlined
                                dense
                                hide-details="auto"
                                placeholder="Icon"
                              ></v-file-input>
                            </v-col>
                            <v-col cols="2" md="2" v-if="imagePreviews.button2_icon != ''">
                              <v-img
                                :src="imagePreviews.button2_icon"
                                :width="100"
                              ></v-img>
                            </v-col>
                          </v-row>
                        </v-col>
                      </template>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-card
            title="Vorschau"
            class="fill-height"
          >
            <v-card-title>Vorschau</v-card-title>
            <v-card-text>

              <v-card-text>
                <push-notification-preview
                  :title="form.message.titel"
                  :text="form.message.text"
                  :domain="form.mainData.domain"
                  :icon="imagePreviews.messageIcon"
                  :image="imagePreviews.messageImage"
                  :button1="{...form.messageButtons[0],icon:imagePreviews.button1_icon}"
                  :button2="{...form.messageButtons[1],icon:imagePreviews.button2_icon}"
                ></push-notification-preview>
              </v-card-text>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="12"
          lg="12"
        >
          <v-card>
            <v-card-title>Versand-Einstellung</v-card-title>
            <v-card-text>
              <v-datetime-picker
                label="Start"
                v-model="form.mainData.zeitVersandStart"
                clearText="Löschen"
                date-format="dd.MM.yyyy"
                :textFieldProps="{outlined:true, dense:true, 'hide-details':'auto'}"
                :datePickerProps="{locale: 'de-de','first-day-of-week':'1'}"
                :timePickerProps="{locale: 'de-de', format:'24hr'}"
                required
                :rules="[validators.dateRules]"
              >
                <template v-slot:dateIcon>
                  <v-icon size="22">
                    {{ icons.mdiCalendarOutline }}
                  </v-icon>
                </template>
                <template v-slot:timeIcon>
                  <v-icon size="22">
                    {{ icons.mdiClockOutline }}
                  </v-icon>
                </template>
              </v-datetime-picker>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              <v-switch
                v-model="showConversionTracking"
                inset
                dense
                hide-details
              ></v-switch>
              Conversion-Tracking
            </v-card-title>
            <v-card-text v-if="showConversionTracking">
              <v-row>
                <v-col cols="12" md="8">
                  <v-select
                    class=""
                    v-model="form.events"
                    multiple
                    hide-details="auto"
                    outlined
                    dense
                    chips
                    :items="events"
                    item-text="displayName"
                    item-value="idNipEvent"
                    label="Auswahl Events"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <router-link :to="'/event'" target= '_blank'>
                    <v-btn
                      color="secondary"
                      class="mx-2"
                    >
                      Events verwalten
                    </v-btn>
                  </router-link>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              <v-switch
                v-model="showAutomation"
                inset
                dense
                hide-details
              ></v-switch>
              Automatisierung
            </v-card-title>
            <v-card-text v-if="showAutomation">
              <v-row>
                <v-col cols="12" md="8">
                  <v-select
                    class=""
                    v-model="form.tags"
                    multiple
                    hide-details="auto"
                    outlined
                    dense
                    chips
                    :items="tags"
                    item-text="displayName"
                    item-value="idNipTag"
                    label="Auswahl Tags"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <router-link :to="'/tag'" target= '_blank'>
                    <v-btn
                      color="secondary"
                      class="mx-2"
                    >
                      Tags verwalten
                    </v-btn>
                  </router-link>
                </v-col>
                <v-col cols="12" md="8">
                  <v-select
                    class=""
                    v-model="form.topics"
                    multiple
                    hide-details="auto"
                    outlined
                    dense
                    chips
                    :items="topics"
                    item-text="displayName"
                    item-value="idNipThemenbereich"
                    label="Auswahl Themenbereiche"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <router-link :to="'/themenbereich'" target= '_blank'>
                    <v-btn
                      color="secondary"
                      class="mx-2"
                    >
                      Themenbereiche verwalten
                    </v-btn>
                  </router-link>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mt-5">
        <v-col cols="12" md="12">
          <router-link :to="'/kampagne'">
            <v-btn
              color="error"
              class="mx-2"
            >
              Zurück
            </v-btn>
          </router-link>
          <v-btn color="primary" type="submit" @click="saveData">
            Speichern
          </v-btn>
          <v-btn
            type="reset"
            outlined
            class="mx-2"
          >
            Zurücksetzen
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>

</template>

<script>
import {onMounted, ref, getCurrentInstance,nextTick} from '@vue/composition-api'
import router from '@/router'
import {
  mdiHomeOutline,
  mdiEmailOutline,
  mdiLockOutline,
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiCalendarOutline,
  mdiClockOutline,
  mdiRefresh
} from '@mdi/js'

import axios from "axios";
import * as validators from "@core/utils/validation";


import {addAlpha, getVuetify} from "@core/utils";
import isBlob from 'is-blob';
import InlineDatePicker
  from "@/views/InlineDatePicker";
import PushNotificationPreview from "@/views/push-notification/Preview";

export default {
  components: {
    PushNotificationPreview,
    VueApexCharts: () => import('vue-apexcharts'),
    InlineDatePicker
  },
  data() {
    return {
      showPreview: true
    }
  },
  setup(props) {

    const userTab = ref(null)
    const tabs = ref([
      { icon: mdiHomeOutline, title: 'Übersicht', id:'overview' },
    ])

    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = 2000;

    const formRef = ref(null)
    const form = ref({})

    const potentialAnalyzeResult = ref({});

    const showConversionTracking = ref(false);
    const showAutomation = ref(false);

    const messageIcon = ref({});
    const messageImage = ref({});
    const button1_icon = ref({});
    const button2_icon = ref({});

    const imagePreviews = ref({
      messageIcon : '',
      messageImage : '',
      button1_icon : '',
      button2_icon : '',
    })

    const deletedImages = [];

    const previewImage = (key)=>{
      if(key == 'messageIcon'){
        imagePreviews.value[key] = isBlob(messageIcon.value) ? URL.createObjectURL(messageIcon.value) : '';
      }
      if(key == 'messageImage'){
        imagePreviews.value[key] = isBlob(messageImage.value) ? URL.createObjectURL(messageImage.value) : '';
      }
      if(key == 'button1_icon'){
        imagePreviews.value[key] = isBlob(button1_icon.value) ? URL.createObjectURL(button1_icon.value) : '';
      }
      if(key == 'button2_icon'){
        imagePreviews.value[key] = isBlob(button2_icon.value) ? URL.createObjectURL(button2_icon.value) : '';
      }
    }

    const deleteImage = (key) => {
      imagePreviews.value[key] = null;
      deletedImages.push(key);
    }

    const channels = ref([]);
    const segments = ref([]);
    const events = ref([]);
    const topics = ref([]);
    const tags = ref([]);

    const genders = ref([
      {label:'Männlich',value:'m'},
      {label:'Weiblich',value:'w'},
      {label:'Divers',value:'d'},
    ]);

    const countries = ref([
      {label:'Deutschland',value:'de'},
      {label:'Österreich',value:'at'},
      {label:'Schweiz',value:'ch'},
    ]);

    const languages = ref([
      {label:'deutsch',value:'de'},
      {label:'österreichisch',value:'at'},
      {label:'schweizerisch',value:'ch'},
    ]);

    const platforms = ref([
      {label:'windows',value:'windows'},
      {label:'mac',value:'mac'},
      {label:'android',value:'android'},
      {label:'ios',value:'ios'},
    ]);

    const buttonActions = ref([
      {label:"Url öffnen", value:"bestaetigen"},
      {label:"Nachricht schließen", value:"abbrechen"},
      {label:"Nachricht zurückstellen", value:"zurueckstellen"}
    ]);

    const freieAuswahlDefault = {
      geschlecht : [],
      laender : [],
      sprachen : [],
      plattformen : [],
      altersgruppe : ref([18,67]),
    };

    const altersgruppe = ref([18,67]);

    form.value = {
      mainData : {
        idNipKampagne : router.currentRoute.params.idNipKampagne,
        name : '',
        freieAuswahlJson : freieAuswahlDefault,
        zeitVersandStart : '',
      },
      message : {
        titel:"",
        text:"",
        url:"",
        icon:"",
        grafik:""
      },
      messageButtons : [
        {key:0,url:'',icon:'',aktion:'',aktiv:false},
        {key:1,url:'',icon:'',aktion:'',aktiv:false},
      ],
      channels : [],
      segments : [],
      events : [],
      tags : [],
      topics : [],
      aktion: (router.currentRoute.params.idNipKampagne !== undefined ? 'editCampaignCheck' : 'createCampaignCheck'),
    };
    const loading = ref(true)

    onMounted(()=>{

      axios.get('/api/ajaxServer/?aktion=fetchChannels').then((response) => channels.value = response.data.channels)
      axios.get('/api/ajaxServer/?aktion=fetchSegments').then((response) => segments.value = response.data.segments)
      axios.get('/api/ajaxServer/?aktion=fetchEvents').then((response) => events.value = response.data.events)
      axios.get('/api/ajaxServer/?aktion=fetchTopics').then((response) => topics.value = response.data.topics)
      axios.get('/api/ajaxServer/?aktion=fetchTags').then((response) => tags.value = response.data.tags)

      if(form.value.aktion == 'editCampaignCheck'){
        axios.post('/api/kampagne/', {
          aktion: 'editCampaign',
          mainData:{
            idNipKampagne : router.currentRoute.params.idNipKampagne,
          }
        })
          .then((response) => {
            if (response.data.valid === true) {

              if(response.data.campaign.zeitVersandStart !== null){
                response.data.campaign.zeitVersandStart = new Date(response.data.campaign.zeitVersandStart);
              }

              form.value.mainData = response.data.campaign;

              showConversionTracking.value = form.value.mainData.events.length > 0;
              showAutomation.value = (form.value.mainData.topics.length > 0 || form.value.mainData.tags.length > 0 );

              if(response.data.campaign.freieAuswahlJson === null || response.data.campaign.freieAuswahlJson.length == 0){
                form.value.mainData.freieAuswahlJson = freieAuswahlDefault;
              }

              if(form.value.mainData.freieAuswahlJson.altersgruppe){
                altersgruppe.value = form.value.mainData.freieAuswahlJson.altersgruppe;
              }

              form.value.message = response.data.campaign.message;
              form.value.messageButtons = response.data.campaign.messageButtons;
              form.value.channels = response.data.campaign.channels;
              form.value.segments = response.data.campaign.segments;
              form.value.events = response.data.campaign.events;
              form.value.topics = response.data.campaign.topics;
              form.value.tags = response.data.campaign.tags;

              for (const [key, item] of Object.entries(response.data.campaign.images)) {
                imagePreviews.value[key] = item.url;
              }

            } else {
              response.data.errors.forEach(item=>{
                let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
                vm.$toastr.e(item.errorText, item.errorTitle+suffix);
              })
            }
            loading.value = false;
          })
          .catch((error) => {
            console.log(error)
            loading.value = true;
          })
      }
      else{
        loading.value = false;
      }
    })

    const saveData = () => {
      const isFormValid = formRef.value.validate()
      if (!isFormValid)   {
        nextTick(() => {
          const firstErrorField = document.querySelector('.error--text');
          if (firstErrorField) {
            firstErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        });
        return
      }
      loading.value = true;

      const fd = new FormData();
      fd.append('aktion',form.value.aktion);
      fd.append('mainData[idNipKampagne]',form.value.mainData.idNipKampagne);
      fd.append('mainData[name]',form.value.mainData.name);

      let zeitVersandStart = '';
      if(form.value.mainData.zeitVersandStart !== null && form.value.mainData.zeitVersandStart !== ''){
        zeitVersandStart = form.value.mainData.zeitVersandStart.toISOString();
      }

      fd.append('mainData[zeitVersandStart]',zeitVersandStart);

      form.value.mainData.freieAuswahlJson.altersgruppe = JSON.parse(JSON.stringify(altersgruppe.value));
      fd.append('mainData[freieAuswahlJson]',JSON.stringify(form.value.mainData.freieAuswahlJson));

      fd.append('message[titel]',form.value.message.titel || '');
      fd.append('message[text]',form.value.message.text || '');
      fd.append('message[url]',form.value.message.url || '');

      form.value.messageButtons.forEach((item,key) => {
        fd.append(`messageButtons[${key}][url]`,item.url);
        fd.append(`messageButtons[${key}][icon]`,item.icon);
        fd.append(`messageButtons[${key}][aktion]`,item.aktion);
        fd.append(`messageButtons[${key}][aktiv]`,item.aktiv);
      })

      form.value.channels.forEach((value,key) => {
        fd.append(`channels[${key}]`,value);
      })

      form.value.segments.forEach((value,key) => {
        fd.append(`segments[${key}]`,value);
      })

      form.value.events.forEach((value,key) => {
        fd.append(`events[${key}]`,value);
      })

      form.value.topics.forEach((value,key) => {
        fd.append(`topics[${key}]`,value);
      })

      form.value.tags.forEach((value,key) => {
        fd.append(`tags[${key}]`,value);
      })

      deletedImages.forEach((value,key) => {
        fd.append(`deletedImages[${key}]`,value);
      })

      if(isBlob(messageIcon.value)){
        fd.append('images[messageIcon]',messageIcon.value,messageIcon.value.name);
      }
      if(isBlob(messageImage.value)){
        fd.append('images[messageImage]',messageImage.value,messageImage.value.name);
      }
      if(isBlob(button1_icon.value)){
        fd.append('images[button1_icon]',button1_icon.value,button1_icon.value.name);
      }
      if(isBlob(button2_icon.value)){
        fd.append('images[button2_icon]',button2_icon.value,button2_icon.value.name);
      }

      axios
        .post('/api/kampagne/',fd)
        .then(response => {
          if(response.data.valid === true){
            router.push('/kampagne',()=>{
              vm.$toastr.s(response.data.message.text, response.data.message.title);
            })
          }
          else{
            response.data.errors.forEach(item=>{
              let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
              vm.$toastr.e(item.errorText, item.errorTitle+suffix);
            })
          }
          loading.value = false;
        })
        .catch(error => {
          vm.$toastr.e(error,error);
          loading.value = false;
        })
    }

    const runPotentialAnalyze = () =>{

      const fd = new FormData();
      fd.append('aktion','runPotentialAnalyze');
      form.value.mainData.freieAuswahlJson.altersgruppe = JSON.parse(JSON.stringify(altersgruppe.value));
      fd.append('mainData[freieAuswahlJson]',JSON.stringify(form.value.mainData.freieAuswahlJson));

      axios
        .post('/api/kampagne/',fd)
        .then(response => {

          console.log(response.data);
          loading.value = false;
          let chartDataValue = [];
          response.data.potentialAnalyzeResult.forEach((item,key) => {
            chartDataValue[key] = item.value;
            chartOptions.value.labels[key] = item.key;
          })

          console.log(chartDataValue)
          chartData.value = chartDataValue;
        })
        .catch(error => {
          vm.$toastr.e(error,error);
          loading.value = false;
        })
    }

    const updateMainData  = (key,value) => {
      nextTick(() => {
        form.value.mainData[key] = value;
      })
    }

    const maxPotential = ref(30);
    const $vuetify = getVuetify()
    const chartOptions = ref({
      labels: ['Apparel', 'Electronics', 'FMCG', 'Other Sales', 'Alt'],
      colors: [
        $vuetify.theme.currentTheme.primary,
        addAlpha($vuetify.theme.currentTheme.primary, 0.7),
        addAlpha($vuetify.theme.currentTheme.primary, 0.3),
        addAlpha($vuetify.theme.currentTheme.secondary, 0.1),
      ],
      chart: {
        type: 'donut',
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {
        pie: {
          donut: {
            size: '80%',
            labels: {
              show: true,
              name: {
                fontSize: '14px',
                offsetY: 25,
              },
              value: {
                fontSize: '2.125rem',
                fontWeight: 600,

                offsetY: -15,
                formatter(value) {
                  return `${value}k`
                },
              },
              total: {
                show: true,
                label: 'Abonennten',
                color: 'rgba(94, 86, 105, 0.68)',
                formatter(value) {
                  return `${value.globals.seriesTotals.reduce((total, num) => total + num)}k`
                },
              },
            },
          },
        },
      },
    })
    const chartData = ref([12, 24.5, 15, 50, 3])


    return {
      maxPotential,
      chartOptions,
      chartData,
      userTab,
      tabs,
      form,
      formRef,
      messageIcon,
      messageImage,
      button1_icon,
      button2_icon,
      saveData,
      loading,
      buttonActions,
      channels,
      segments,
      events,
      topics,
      tags,
      genders,
      countries,
      languages,
      platforms,
      previewImage,
      deleteImage,
      imagePreviews,
      altersgruppe,
      updateMainData,
      runPotentialAnalyze,
      showConversionTracking,
      showAutomation,
      validators,
      icons: {
        mdiHomeOutline,
        mdiEmailOutline,
        mdiLockOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
        mdiCalendarOutline,
        mdiClockOutline,
        mdiRefresh,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';

.user-tabs{
  display: none;
}
#user-tabs-content{
  margin:0 !important;
}

#chart-sales-overview {
  .apexcharts-canvas {
    .apexcharts-text {
      &.apexcharts-datalabel-value {
        font-weight: 600;
      }
      &.apexcharts-datalabel-label {
        font-size: 1rem;
      }
    }
  }
}

.sales-overview-stats-table {
  width: 100%;
  td {
    padding-bottom: 1rem;
  }

  .stats-dot {
    padding: 0.33rem;
  }

  // Set opacity of dots
  tr {
    &:nth-of-type(1) {
      td:nth-of-type(2) {
        .stats-dot {
          opacity: 0.7;
        }
      }
    }
    &:nth-of-type(2) {
      td:nth-of-type(1) {
        .stats-dot {
          opacity: 0.5;
        }
      }
      td:nth-of-type(2) {
        .stats-dot {
          opacity: 0.15;
        }
      }
    }
  }
}

.v-card__subtitle, .v-card__text #chart-sales-overview{
  font-size: unset;
}

.v-file-input {
  flex-direction: row-reverse;
}
.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}

.v-input__append-outer {
  white-space: nowrap;
}

.gamification-tree-4,
.gamification-john-pose-2,
.gamification-tree {
  position: absolute;
}
.gamification-tree {
  top: 10%;
  right: 0;
}
.gamification-john-pose-2 {
  bottom: 0;
  right: 15%;
}
.gamification-tree-4 {
  bottom: 0;
  right: 30%;
}

.refreshIcon {
  position: absolute !important;
  right: 30px;
}

@media (max-width: 600px) {
  .gamification-tree,
  .gamification-tree-4 {
    display: none;
  }
  .gamification-john-pose-2 {
    right: 5%;
  }
}

@media (max-width: 500px) {
  .gamification-john-pose-2 {
    max-width: 70px;
  }
}

@media (max-width: 400px) {
  .greeting-title {
    font-size: 1.2rem !important;
  }
}

// rtl
.v-application {
  &.v-application--is-rtl {
    .gamification-john-pose-2 {
      right: initial;
      left: 15%;
    }
    .gamification-tree {
      right: initial;
      left: 0;
    }
    .gamification-tree-4 {
      left: 30%;
      right: initial;
    }
  }
}
.v-card > :first-child:not(.v-btn):not(.v-chip):not(.v-avatar), .v-card > .v-card__progress + :not(.v-btn):not(.v-chip):not(.v-avatar) {
  border-top-left-radius: unset;
  border-top-right-radius: unset;
}
</style>
